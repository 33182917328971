import { login, loginByQYUserId, loginByWxUserId, loginByDingTalk, logout } from '@/api/os/login'
import { changePublisher, changeLastReadWord } from '@/api/os/user'
import { getPublisherSetting } from '@/api/os/company'
import { getStorage, setStorage, removeStorage } from '@/utils/storage'
import { getMenuByUser } from '@/api/os/menu'

const tokenKey = 'workbench-token'
const authToken = 'ooh-token'
const expireSecond = 10800
// 创建用户状态
const user = {
  state: {
    user: '',
    name: '',
    token: getStorage(tokenKey),
    roles: []
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },
  getters: {
    token: state => state.token
  },
  actions: {
    // 用户名登录
    LoginByUsername ({ commit }, userInfo) {
      // 登录前清除缓存
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeStorage(tokenKey)
      removeStorage(authToken)
      removeStorage('refresh-token')

      // const postData = {
      //   username: userInfo.username.trim(),
      //   password: userInfo.password
      // }
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          saveUserInfo({ commit }, resolve, reject, response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 微信/企业微信登录
    LoginByScan ({ commit }, loginBean) {
      // 登录前清除缓存
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeStorage(tokenKey)
      removeStorage(authToken)
      removeStorage('refresh-token')

      return new Promise((resolve, reject) => {
        if (loginBean.type === 'wx') {
          loginByWxUserId(loginBean).then(response => {
            saveUserInfo({ commit }, resolve, reject, response)
          }).catch(error => {
            reject(error)
          })
        } else if (loginBean.type === 'dt') {
          loginByDingTalk(loginBean).then(response => {
            saveUserInfo({ commit }, resolve, reject, response)
          }).catch(error => {
            reject(error)
          })
        } else {
          loginByQYUserId(loginBean).then(response => {
            saveUserInfo({ commit }, resolve, reject, response)
          }).catch(error => {
            reject(error)
          })
        }
      })
    },
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then(response => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          // 取消所有请求
          window.axiosCancel.forEach((ele, index) => {
            ele.cancel()
            delete window.axiosCancel[index]
          })

          // 指定部分属性值不需要清除
          const version = window.localStorage.getItem('oohforce-workbench')
          const theme = getStorage('oohforce-workbench-theme') || 'dark'

          window.localStorage.clear()
          window.localStorage.setItem('oohforce-workbench', version)
          setStorage('oohforce-workbench-theme', theme)

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    changepublisher ({ commit }, publisherInfo) {
      // 登录前清除缓存
      // commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      // removeStorage(tokenKey)
      // removeStorage(authToken)
      // removeStorage('refresh-token')

      // const postData = {
      //   username: userInfo.username.trim(),
      //   password: userInfo.password
      // }
      return new Promise((resolve, reject) => {
        changePublisher(publisherInfo).then(response => {
          saveUserInfo({ commit }, resolve, reject, response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 变更用户最后一次阅读的更新公告
    changeLastReadWord ({ commit }, changeInfo) {
      // 登录前清除缓存
      return new Promise((resolve, reject) => {
        changeLastReadWord(changeInfo).then(response => {
          if (response) {
            const userInfoData = user.state.token
            userInfoData.userInfo.lastReadWord = changeInfo.lastReadWord
            commit('SET_TOKEN', userInfoData)
            setStorage(tokenKey, userInfoData, expireSecond)
            resolve()
          }
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

// 登录后数据统一处理
function saveUserInfo ({ commit }, resolve, reject, response) {
  if (response.errcode !== undefined) {
    reject(response.errmsg)
  } else if (response.userInfo.stationRole.length === 0) {
    reject('用户未配置权限！')
  } else {
    // 保存token
    if (response.jwt) {
      setStorage(authToken, `${response.jwt.token_type} ${response.jwt.access_token}`, expireSecond)
      setStorage('refresh-token', response.jwt.refresh_token, expireSecond)
    }
    getMenuByUser({ userId: response.userInfo.userId, sign: 9 }).then(res => {
      if (res && !res.errcode) {
        const menuByPlatform = res.filter(x => x.menuList.length > 0)

        // 登录时存储当前用户所有平台菜单数据, 如果包含运营态势， 那么提到最前列
        let menus = []
        if (menuByPlatform.find(x => x.platformId === 19)) {
          menus.push(menuByPlatform.find(x => x.platformId === 19))
          menuByPlatform.forEach(element => {
            if (element.platformId !== 19) {
              menus.push(element)
            }
          })
        } else {
          menus = menuByPlatform
        }

        // const menus = res.sort((a, b) => {
        //   return (b.platformName.length > a.platformName.length || b.platformId < a.platformId) ? 1 : -1
        // })

        setStorage('userMenus', menus)
        // 默认存储用户当前第一个平台菜单数据
        setStorage('userShowMenu', menus[0])
        // 获取默认平台菜单权限标识
        var auths = []
        // menus.forEach(item => {
        //   getAuthSignData(item.menuList, auths)
        // })
        getAuthSignData(menus[0] ? menus[0].menuList : [], auths)
        setStorage('userAuth', auths)
      }
    }).then(() => {
      // 获取媒介主信息, 最新为数组， 暂时取值第一条
      let userInfoData = {
        roles: response.userInfo.stationRole,
        userInfo: response.userInfo
      }
      const publisherId = response.userInfo.publisherId
      if (publisherId) {
        // 获取租户设置
        getPublisherSetting({ companyId: publisherId }).then(settings => {
          if (settings && !settings.errcode) {
            userInfoData = Object.assign({}, userInfoData, { publisherSetting: settings })
          }
          commit('SET_TOKEN', userInfoData)
          setStorage(tokenKey, userInfoData, expireSecond)
          resolve()
        })
        // 验证是否包含代理商
      } else {
        commit('SET_TOKEN', userInfoData)
        setStorage(tokenKey, userInfoData, expireSecond)
        resolve()
      }
    })
  }
}

/**
 * 获取所有权限标识
 * @param {Array} sourceData
 * @param {Array} targetData
 */
function getAuthSignData (sourceData, targetData = []) {
  sourceData.forEach(item => {
    if (item.children && item.children.length) {
      if (item.category === 3) {
        targetData.push(item.code)
      }
      getAuthSignData(item.children, targetData)
    } else {
      if (item.category === 3) {
        targetData.push(item.code)
      }
    }
  })
}

export default user
